$(document).ready(function() {

  // Service carousel
  $(".carousel").slick({
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1240,
        settings: {
          dots: false,
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 940,
        settings: {
          dots: false,
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 600,
        settings: {
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  });

  // Navbar headroom
  // var header = document.querySelector(".navbar");
  // var headroomOption = {
  //     "offset": 205,
  //     "tolerance": 5,
  //     "classes": {
  //       "initial": "animated",
  //       "pinned": "slideDown",
  //       "unpinned": "slideUp"
  //     }
  // };
  // var headroom = new Headroom(header, headroomOption);
  // headroom.init();

  // Smooth scroll
  $('.navbar__link').bind('click', function () {
      var padding = $(".navbar").height();

        $('html, body').stop().animate({
            scrollTop: $($(this).attr('href')).offset().top - padding
        }, 1500, 'easeInOutExpo');
        event.preventDefault();
    });

});